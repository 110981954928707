import "./about.css";
import React from "react";
import TitleBox from "../../components/TitleBox/TitleBox";
import { aboutImages, aboutVideos } from "../../assets/images/aboutImg";
import { useTranslation } from "react-i18next";
import { qualityPdf } from "../../assets/pdf";

function About() {
  const { t } = useTranslation();

  return (
    <div className="about-container route">
      <div className="about-header">
        <div className="header-back-img">
          <img src={aboutImages.aboutheader} alt="header-img" />
        </div>

        <div className="header-front-container">
          <div className="header-title">
            <TitleBox title={t("about")} />
          </div>

          <div className="header-front-img">
            <img src={aboutVideos.headerVideo1} alt="header-front-img" />
          </div>

          <section className="about-section">
            <div className="history-image">
              <img src={aboutImages.payeHistory} alt="history-img" />
            </div>

            <div className="text">
              <h4 className="title">PAYE PLAST TİCARET VE SANAYİ LTD. ŞTİ.</h4>

              <p>
                {t("homepage_who_are_we_text1")} <br />
                {t("homepage_who_are_we_text2")}{" "}
              </p>
              <br />
              <p>{t("homepage_who_are_we_text3")}</p>
              <br />
              <p>{t("homepage_who_are_we_text6")}</p>
              <br />
              <p>{t("homepage_who_are_we_text4")}</p>
              <br />
              <p>{t("homepage_who_are_we_text5")}</p>
            </div>

            <div className="quality-policy">
              {/* <div className="paye-logo">
              </div> */}
                <img src={aboutImages.payeLogo} alt="logo" className="paye-logo" />
              <h2>{t("about_quality-policy_title")}</h2>
              <p>{t("about_quality-policy_text1")}</p>
              <p>{t("about_quality-policy_text2")}</p>
              <p>{t("about_quality-policy_text3")}</p>
              <p>{t("about_quality-policy_text4")}</p>
              <p>{t("about_quality-policy_text5")}</p>
              <p>{t("about_quality-policy_text6")}</p>
              <p>{t("about_quality-policy_text7")}</p>
              <p>{t("about_quality-policy_text8")}</p>

              <div className="manager-name">
                <h3>PAYE PLAST SAN.VE TİC.AŞ.</h3>
                <p>{t("about_quality-policy_manager_text")}</p>
                <p>ORHAN KUŞ</p>
                <p>10.05.2023</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default About;

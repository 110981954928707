import ducato1 from "./minibus/DUCATO L4H2 - L3H2/DUCATO L4H2.webp";
import ducato2 from "./minibus/DUCATO L4H2 - L3H2/WhatsApp Image 2019-09-24 at 12.22.58.webp";
import ducato3 from "./minibus/DUCATO L4H2 - L3H2/WhatsApp Image 2019-09-24 at 12.22.59(1).webp";
import ducato4 from "./minibus/DUCATO L4H2 - L3H2/WhatsApp Image 2019-09-24 at 12.22.59.webp";

import fordTransit1 from "./minibus/FORD TRANSİT L4H3/FORD TRANSIT L4H3 - JUMBO - Kopya.webp";
import fordTransit2 from "./minibus/FORD TRANSİT L4H3/Ford Transit Jumbo - Kopya.webp";
import fordTransit3 from "./minibus/FORD TRANSİT L4H3/Mietwagen-Ford-Transit-l.webp";

import hyundai1 from "./minibus/HYUNDAI H-350/RAL7035.webp";
import hyundai2 from "./minibus/HYUNDAI H-350/hyundai - h350.webp";

import renultmaster1 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L3H2/RM-L3H2.webp";
import renultmaster2 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L3H2/WhatsApp Image 2021-01-07 at 13.10.55 (1).webp";
import renultmaster3 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L4H2/img1~1.webp";
import renultmaster4 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L4H2/img2~1.webp";
import renultmaster5 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L4H2/img3~1.webp";
import renultmaster6 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L4H3/Arka Kapı.webp";
import renultmaster7 from "./minibus/RM-L3,L4,L5/RENAULT MASTER L4H3/RM-L4H3.webp";

import sprinter1 from "./minibus/SPRINTER L3-L4/WhatsApp Image 2021-02-25 at 16.59.28 (1).webp";
import sprinter2 from "./minibus/SPRINTER L3-L4/sprinter (2).webp";
import sprinter3 from "./minibus/SPRINTER L3-L4/sprinter (3).webp";

import crafter1 from "./minibus/VW- CRAFTER L3-L4-l5/New Crafter L4.webp";
import crafter2 from "./minibus/VW- CRAFTER L3-L4-l5/VW-Crafter-04.webp";
import crafter3 from "./minibus/VW- CRAFTER L3-L4-l5/crafter l3 (1).webp";
import crafter4 from "./minibus/VW- CRAFTER L3-L4-l5/crafter l3 (2).webp";

// const ducato1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fducato%2FDUCATO%20L4H2.jpeg?alt=media&token=0ec4b8b8-0645-4655-ba69-d5acbcbdbadd"
// const ducato2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fducato%2FWhatsApp%20Image%202019-09-24%20at%2012.22.58.jpeg?alt=media&token=4776452e-0bdf-4c07-be85-954e7a8b7fc6"
// const ducato3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fducato%2FWhatsApp%20Image%202019-09-24%20at%2012.22.59(1).jpeg?alt=media&token=d092f477-cc2e-4a37-a258-c0fe7516c340"
// const ducato4 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fducato%2FWhatsApp%20Image%202019-09-24%20at%2012.22.59.jpeg?alt=media&token=50feab8c-a21f-4d18-b746-c3e4edb7fd1d"

// const fordTransit1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fford%2FFORD%20TRANSIT%20L4H3%20-%20JUMBO%20-%20Kopya.jpg?alt=media&token=fe9fd4b5-79b9-4bfc-8541-55d4d506df20"
// const fordTransit2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fford%2FFord%20Transit%20Jumbo%20-%20Kopya.jpg?alt=media&token=7ee4c9ff-c7d7-434b-a562-754eb49cfb08"
// const fordTransit3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fford%2FMietwagen-Ford-Transit-l.jpg?alt=media&token=3d7b75a7-06d0-47c1-8d3b-385c98bc0d17"

// const hyundai1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fhyundai%2FRAL7035.jpg?alt=media&token=bb0826a9-233a-491e-9b4a-e0a7718340e8"
// const hyundai2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fhyundai%2Fhyundai%20-%20h350.jpg?alt=media&token=58f78e5d-15e6-499d-8695-6da949e270be"

// const renultmaster1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2FArka%20Kap%C4%B1.jpg?alt=media&token=d1dfcf12-1c15-464a-b1c2-a29e68f49f51"
// const renultmaster2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2FRM-L3H2.jpeg?alt=media&token=3c42322b-83ff-4f42-92b5-757a8124802f"
// const renultmaster3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2FRM-L4H3.png?alt=media&token=ad9d7666-f4ef-4354-9745-f63572bd97c6"
// const renultmaster4 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2FWhatsApp%20Image%202021-01-07%20at%2013.10.55%20(1).jpeg?alt=media&token=1d55d13b-1e7d-4914-b20f-c4253d8ef095"
// const renultmaster5 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2Fimg1~1.jpg?alt=media&token=b417eef2-a95b-404d-ba24-b9699818b75e"
// const renultmaster6 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2Fimg2~1.jpg?alt=media&token=af1e8030-d181-4736-a51d-439a9508dd69"
// const renultmaster7 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Frenault%2Fimg3~1.jpg?alt=media&token=a60f351b-58b9-4edd-97cd-92fda0349390"

// const sprinter1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fsprinter%2FWhatsApp%20Image%202021-02-25%20at%2016.59.28%20(1).jpeg?alt=media&token=63737748-4e95-4e93-b14c-50aa3d386ca9"
// const sprinter2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fsprinter%2Fsprinter%20(2).jpeg?alt=media&token=12a8bde9-9bab-47a4-962f-d69293cef50b"
// const sprinter3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fsprinter%2Fsprinter%20(3).jpeg?alt=media&token=c5d24c9d-2713-417e-ba79-cd48a6227cf6"

// const crafter1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fwm-crafter%2FNew%20Crafter%20L4.jpg?alt=media&token=b202f32c-4e7f-4c6e-a3c4-bfe7efb31006"
// const crafter2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fwm-crafter%2FVW-Crafter-04.png?alt=media&token=3c6f897d-d9c8-473e-96db-890531fa2678"
// const crafter3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fwm-crafter%2Fcrafter%20l3%20(1).jpeg?alt=media&token=7edaaacb-4ea2-4b2e-9108-26c19705a091"
// const crafter4 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductDetailImg%2Fminibus%2Fwm-crafter%2Fcrafter%20l3%20(2).jpeg?alt=media&token=a5c7c7b4-a57c-4fe2-81c3-7e6a199e8406"

export const productsDetail = [
  {
    productId: 1,
    productName: "OTOBÜS",
    brands: [],
  },
  {
    productId: 2,
    productName: "MİNİBÜS",
    brands: [
      {
        name: "FIAT DUCATO L4H2 - L3H2 PEUGEOT BOXER L4H2 - L3H2 CITROEN JUMPER L4H2 - L3H2",
        src: [ducato1, ducato2, ducato3, ducato4],
      },
      {
        name: "FORD TRANSİT L4H3",
        src: [fordTransit1, fordTransit2, fordTransit3],
      },
      {
        name: "HYUNDAI H-350",
        src: [hyundai1, hyundai2],
      },
      {
        name: "RENAULT MASTER-L3,L4,L5",
        src: [
          renultmaster1,
          renultmaster2,
          renultmaster3,
          renultmaster4,
          renultmaster5,
          renultmaster6,
          renultmaster7,
        ],
      },
      {
        name: "MERCEDES SPRINTER L3-L4",
        src: [sprinter1, sprinter2, sprinter3],
      },
      {
        name: "VW- CRAFTER L3-L4-l5",
        src: [crafter1, crafter2, crafter3, crafter4],
      },
    ],
  },
  {
    productId: 3,
    productName: "KAMYON",
    brands: [],
  },
  {
    productId: 4,
    productName: "HAVACILIK",
    brands: [],
  },
  {
    productId: 5,
    productName: "TRAKTÖR",
    brands: [],
  },
  {
    productId: 6,
    productName: "ELEKTRİKLİ ARAÇ",
    brands: [],
  },
  {
    productId: 7,
    productName: "SAVUNMA SANAYİ ARAÇLARI",
    brands: [],
  },
  {
    productId: 8,
    productName: "OTOMOBİL",
    brands: [],
  },
];

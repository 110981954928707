import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import tr_lang from "./lang/tr.json";
import en_lang from "./lang/en.json";

i18n.use(initReactI18next).init({
  resources: {
    tr: {
      translation: tr_lang,
    },
    en: {
      translation: en_lang,
    },
  },
  lng: "tr", // if you're using a language detector, do not define the lng option
  fallbackLng: "tr",
});

export default i18n;

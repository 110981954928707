import "./youtubeVideo.css";
import YouTube from "react-youtube";

function YoutubeVideo({ videoId, autoplay, mute }) {
  const onPlayerReady = (event) => {
    if (autoplay) {
      event.target.playVideo();
    }
  };

  const opts = {
    height: "100%",
    width: "100%",

    playerVars: {
      autoplay: autoplay ? 1 : 0,
      controls: 1,
      rel: 0,
      showinfo: 0,
      mute: mute ? 1 : 0,
      loop: 1,
    },
  };
  return (
    <YouTube
      className="my-youtube-component"
      videoId={videoId}
      opts={opts}
      onReady={onPlayerReady}
      onEnd={onPlayerReady}
    />
  );
}

export default YoutubeVideo;

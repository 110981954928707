import "./productsLayout.css";
import React from "react";
import { Outlet } from "react-router-dom";
import { productsImage } from "../../assets/images/productsImg";

function ProductsLayout() {
  return (
    <div className="products-container">
      <div className="products-header">
        <div className="header-back-img">
          <img src={productsImage.productsheaderback} alt="header-img" />
        </div>

        <Outlet />
      </div>
    </div>
  );
}

export default ProductsLayout;

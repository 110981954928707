import "./referances.css";
import React from "react";
import {
  referancesImages,
  referancesHeaderImg,
} from "../../assets/images/referances";
import TitleBox from "../../components/TitleBox/TitleBox";
import ReferancesCard from "../../components/ReferancesCard/ReferancesCard";
import { useTranslation } from "react-i18next";

function Referances() {
  const { t } = useTranslation();

  return (
    <div className="referances-container">
      <div className="referances-header">
        <div className="header-back-img">
          <img
            src={referancesHeaderImg.referancesHeaderBack}
            alt="header-img"
          />
        </div>

        <div className="front-container">
          <div className="header-title">
            <TitleBox title={t("references")} />
          </div>
          <div className="images-container">
            {referancesImages &&
              referancesImages.map((ref, index) => {
                return <ReferancesCard image={ref} key={index} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referances;

import referancesHeaderBack from "./referances_back_img.webp";
import ref1 from "./ref1.webp";
import ref2 from "./ref2.webp";
import ref3 from "./ref3.webp";
import ref4 from "./ref4.webp";
import ref5 from "./ref5.webp";
import ref6 from "./ref6.webp";
import ref7 from "./ref7.webp";
import ref8 from "./ref8.webp";
import ref9 from "./ref9.webp";
import ref10 from "./ref10.webp";
import ref11 from "./ref11.webp";
import ref12 from "./ref12.webp";
import ref13 from "./ref13.webp";
import ref14 from "./ref14.webp";
import ref15 from "./ref15.webp";
import ref16 from "./ref16.webp";
import ref17 from "./ref17.webp";
import ref18 from "./ref18.webp";
import ref19 from "./ref19.webp";
import ref20 from "./ref20.webp";
import ref21 from "./ref21.webp";
import ref22 from "./ref22.webp";
import ref23 from "./ref23.webp";
import ref24 from "./ref24.webp";
import ref26 from "./ref26.webp";
import ref27 from "./ref27.webp";
import ref29 from "./ref29.webp";
import ref30 from "./ref30.webp";
import ref31 from "./ref31.webp";
import ref32 from "./ref32.webp";
import ref33 from "./ref33.webp";
import ref34 from "./ref34.webp";
import ref35 from "./ref35.webp";

// const referancesHeaderBack = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Freferances_back_img.png?alt=media&token=5ffae7a2-8951-4130-a253-a4429936aac6"
// const ref1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref1.png?alt=media&token=9bf28f2a-ce22-4a10-923b-f1816e8d9a96"
// const ref10 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref10.png?alt=media&token=f832c539-1fc9-4f14-a2c9-4aa881693c54"
// const ref11 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref11.png?alt=media&token=772f3c29-7eed-46a2-af50-4b7666174c7a"
// const ref12 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref12.png?alt=media&token=1d9be2a9-5ad6-4120-9281-4517fb80a345"
// const ref13 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref13.png?alt=media&token=e6e29561-6424-410e-a037-d7609ca68eac"
// const ref14 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref14.png?alt=media&token=07bfcb1e-d923-47e8-9aa8-206c258df46f"
// const ref15 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref15.png?alt=media&token=d0a7d132-af0a-4bd8-9c99-7bf9ebe17708"
// const ref16 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref16.png?alt=media&token=0ddbceb2-07bc-4ee2-bbce-22edab94e3fb"
// const ref17 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref17.png?alt=media&token=55a1a1cd-f9d6-415e-a184-7fe3cfac86b2"
// const ref18 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref18.png?alt=media&token=c26d8ddc-db30-44a9-8ecd-1aee47adf77f"
// const ref19 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref19.png?alt=media&token=90ad5c84-c7c4-4990-99f0-eff772ba5dd9"
// const ref2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref2.png?alt=media&token=23d873ce-2dca-4565-ba3f-d680e83e14cc"
// const ref20 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref20.png?alt=media&token=8f855cce-3db6-4560-b90e-a7d2d06ba666"
// const ref21 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref21.png?alt=media&token=78ee2523-40ee-40e7-9391-06ba9728109d"
// const ref22 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref22.png?alt=media&token=d19b44ee-a1a4-43c6-bde5-1cec8e364ba4"
// const ref23 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref23.png?alt=media&token=1ceb4ab2-c56e-4860-ba9f-7d22717a5be9"
// const ref24 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref24.png?alt=media&token=c2a8459c-806e-4b51-a085-be34af41da20"
// const ref26 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref26.png?alt=media&token=2216bdbf-2141-4822-8e9c-91c9c4242f04"
// const ref27 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref27.png?alt=media&token=b9a4e5e8-2eec-4c4a-b4d2-2c3c4aed369a"
// const ref29 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref29.png?alt=media&token=6cceafaf-fe6e-4716-b34e-f08a789c8a6b"
// const ref3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref3.png?alt=media&token=6e4c61b2-991e-42d3-a773-01712719c2a2"
// const ref30 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref30.png?alt=media&token=1648e7a9-0d23-49fb-93c4-f7ba407e4dd2"
// const ref31 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref31.png?alt=media&token=06b69584-f4d8-4a2c-8824-e56d68876cb4"
// const ref32 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref32.png?alt=media&token=a2c3a336-abc6-4d78-ad81-fb5bc842290e"
// const ref33 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref33.png?alt=media&token=c575243a-9720-4a10-9bbb-c8c9a44dcbeb"
// const ref34 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref34.png?alt=media&token=42fc945f-87f8-4f59-af81-485e2b890941"
// const ref35 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref35.png?alt=media&token=5be59309-8e47-42ed-8543-9a942398adce"
// const ref4 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref4.png?alt=media&token=341f61bb-620c-4c44-aead-bb7cca3366a0"
// const ref5 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref5.png?alt=media&token=d44d08c3-3cc7-4c5c-8a6d-6190180c0c86"
// const ref6 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref6.png?alt=media&token=40738968-61e6-44ad-9594-46fb0f467df9"
// const ref7 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref7.png?alt=media&token=87b79ef3-f476-47eb-b770-877f746954ff"
// const ref8 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref8.png?alt=media&token=3ab081c6-158f-401e-ae48-0b61c6acea66"
// const ref9 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2Freferances%2Fref9.png?alt=media&token=f5de6819-796f-45d8-858c-f83abefab782"

export const referancesImages = [
  ref1,
  ref2,
  ref3,
  ref4,
  ref5,
  ref6,
  ref7,
  ref8,
  ref9,
  ref10,
  ref11,
  ref12,
  ref13,
  ref14,
  ref15,
  ref16,
  ref17,
  ref18,
  ref19,
  ref20,
  ref21,
  ref22,
  ref23,
  ref24,
  ref26,
  ref27,
  ref29,
  ref30,
  ref31,
  ref32,
  ref33,
  ref34,
  ref35,
];

export const referancesHeaderImg = {
  referancesHeaderBack,
};

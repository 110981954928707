import "./galery.css";
import React, { useEffect, useState } from "react";
import { galeryImages, galeryHeaderImg } from "../../assets/images/galeryImg";
// import { videos } from "../../assets/video/index";
import TitleBox from "../../components/TitleBox/TitleBox";
import ModalCarousel from "../../components/Modals/ModalCarousel/ModalCarousel";
import { useTranslation } from "react-i18next";
import GaleryComponent from "../../components/GaleryComponent/GaleryComponent";
import { videos } from "../../assets/video";

function Galery() {
  const { t } = useTranslation();

  const [galeryData, setGaleryData] = useState([]);
  const [selectDataIndex, setSelectDataIndex] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  function handleClick(index) {
    setSelectDataIndex(index);
    handleOpenModal();
  }

  useEffect(() => {
    if (galeryImages) {
      // const newData = [
      //   { type: "video", src: videos.payeplast_tanitim, alt: "Video 1" },
      //   { type: "image", src: galeryImages.galeryimg1, alt: "Resim 1" },
      //   { type: "image", src: galeryImages.galeryimg2, alt: "Resim 7" },
      //   { type: "image", src: galeryImages.galeryimg3, alt: "Resim 8" },
      //   { type: "image", src: galeryImages.galeryimg5, alt: "Resim 10" },

      //   { type: "video", src: galeryImages.video3Png, alt: "Video 2" },
      //   { type: "image", src: galeryImages.galeryimg7, alt: "Resim 7" },
      //   { type: "image", src: galeryImages.galeryimg8, alt: "Resim 8" },
      //   { type: "image", src: galeryImages.galeryimg9, alt: "Resim 9" },

      //   { type: "image", src: galeryImages.galeryimg10, alt: "Resim 10" },
      //   { type: "video", src: galeryImages.video4Png, alt: "Video 3" },
      //   { type: "image", src: galeryImages.galeryimg11, alt: "Resim 11" },
      //   { type: "image", src: galeryImages.galeryimg12, alt: "Resim 12" },
      //   { type: "image", src: galeryImages.galeryimg13, alt: "Resim 13" },

      //   { type: "video", src: galeryImages.video5Png, alt: "Video 4" },
      //   { type: "video", src: galeryImages.video6Png, alt: "Video 5" },
      //   { type: "video", src: galeryImages.video7Png, alt: "Video 6" },
      //   { type: "video", src: galeryImages.video8Png, alt: "Video 7" },
      // ];
      const newData = [
        {
          type: "video",
          src: "nZUNy0kxryI",
          duration: "02:46",
          alt: "Video 1",
        },
        { type: "image", src: galeryImages.galeryimg1, alt: "Resim 1" },
        { type: "image", src: galeryImages.galeryimg2, alt: "Resim 2" },
        { type: "image", src: galeryImages.galeryimg3, alt: "Resim 3" },

        {
          type: "video",
          src: "7zGz51QGZAQ",
          duration: "00:27",
          alt: "Video 8",
        },
        { type: "image", src: galeryImages.galeryimg5, alt: "Resim 5" },
        { type: "image", src: galeryImages.galeryimg6, alt: "Resim 6" },
        { type: "image", src: galeryImages.galeryimg7, alt: "Resim 7" },
        { type: "image", src: galeryImages.galeryimg8, alt: "Resim 8" },
        {
          type: "video",
          src: "joSxx0XGoUE",
          duration: "00:13",
          alt: "Video 3",
        },
        { type: "image", src: galeryImages.galeryimg9, alt: "Resim 9" },
        { type: "image", src: galeryImages.galeryimg10, alt: "Resim 10" },
        { type: "image", src: galeryImages.galeryimg11, alt: "Resim 11" },
        {
          type: "video",
          src: "o7AfUT5UZYo",
          duration: "00:08",
          alt: "Video 4",
        },
        { type: "image", src: galeryImages.galeryimg12, alt: "Resim 12" },
        { type: "image", src: galeryImages.galeryimg13, alt: "Resim 13" },
        {
          type: "video",
          src: "dPAg2KScsEM",
          duration: "00:38",
          alt: "Video 5",
        },
        {
          type: "video",
          src: "DVdzNgPiWnU",
          duration: "00:25",
          alt: "Video 6",
        },
        {
          type: "video",
          src: "rK2zPKVG4OU",
          duration: "00:24",
          alt: "Video 7",
        },
      ];
      setGaleryData(newData);
    }
  }, [galeryImages]);

  return (
    <>
      <div className="galery-container">
        <div className="galery-header">
          <div className="header-back-img">
            <img src={galeryHeaderImg.galeryHeaderBack} alt="header-img" />
          </div>

          <div className="front-container">
            <div className="header-title">
              <TitleBox title={t("gallery")} />
            </div>
            <GaleryComponent data={galeryData} handleClick={handleClick} />
          </div>
        </div>
      </div>
      {openModal && (
        <ModalCarousel
          selectDataIndex={selectDataIndex}
          data={galeryData}
          openModalCarousel={openModal}
          handleCloseModalCarousel={handleCloseModal}
        />
      )}
    </>
  );
}

export default Galery;

import "./infoModal.css";
import { useTranslation } from "react-i18next";

function InfoModal({ info, handleCloseModal }) {
  const { t } = useTranslation();
  return (
    <div className="info-modal-container" onClick={handleCloseModal}>
      <div className="info-modal-box">
        <div className="modal-close" onClick={handleCloseModal}>
          X
        </div>
        <div className="info-main">
          <div className="title">
            <span className="material-symbols-outlined">info</span>
            <h4>{t("title_info")}</h4>
          </div>
          <div className="info">
            <p>{info}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoModal;

import "./referancesCard.css";
import React from "react";

function ReferancesCard({ image }) {
  return (
    <div className="referances-card-container">
      <img src={image} alt="referances-img" />
    </div>
  );
}

export default ReferancesCard;

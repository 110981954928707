import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";

function Layout() {
  const { pathname } = useLocation();

  return (
    <div className="layout">
      <Navbar />

      <main>
        <Outlet />
      </main>
      {pathname !== "/contact" ? <Footer /> : null}
    </div>
  );
}

export default Layout;

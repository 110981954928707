import productsheaderback from "./productsheaderback.webp";
import bus from "./bus.webp";
import minibus from "./minibus.webp";
import kamyon from "./kamyon.webp";
import havacilik from "./havacilik.webp";
import traktor from "./traktor.webp";
import elekrikli_arac from "./elekrikli_arac.webp";
import savunma_sanayi from "./savunma_sanayi.webp";
import otomobil from "./otomobil.webp";

// const productsheaderback = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fproductsheaderback.png?alt=media&token=b1559573-b6b1-41e4-a188-6fffdad1f04a";
// const bus = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fbus.png?alt=media&token=71b66d91-93a1-4705-9795-77dd2b5bc45d";
// const minibus = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fminibus.jpg?alt=media&token=be8671af-4714-4e36-8b22-1ca9c96c8825";
// const kamyon = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fkamyon.png?alt=media&token=a6a267e0-2be9-4a65-9bee-3c5e75b440bb";
// const havacilik = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fhavacilik.jpg?alt=media&token=039531f0-72ee-499b-ba84-196792110c37";
// const traktor = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Ftraktor.jpg?alt=media&token=08d9d63e-757b-40af-b99e-240970f856e7";
// const elekrikli_arac = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Felekrikli_arac.png?alt=media&token=b98ef4b7-e9ec-41e1-8b9d-a300eb2c0689";
// const savunma_sanayi = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fsavunma_sanayi.png?alt=media&token=85693b6e-ad71-4f6d-8fe8-c0f6e4951c7c";
// const otomobil = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FproductsImg%2Fotomobil.jpg?alt=media&token=60568f01-9a96-4652-8f6b-b9f267f0fd6a";

export const productsImage = {
  productsheaderback,
  bus,
  minibus,
  kamyon,
  havacilik,
  traktor,
  elekrikli_arac,
  savunma_sanayi,
  otomobil,
};

import extrusion1 from "./extrusionimg1.webp";

import leatherPattern from "./Deri Desen.webp";
import finePattern from "./İnce Desen.webp";
import linenPattern from "./Keten Desen.webp";
import sandPattern from "./Kum Desen.webp";
import mercedesPattern from "./Mercedes Desen.webp";
import meteorPattern from "./Meteor Desen.webp";
import pointPattern from "./Nokta Desen.webp";
import r8Pattern from "./R8 Desen.webp";

// const extrusion1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2Fextrusionimg1.webp?alt=media&token=3ff88bc3-d8c6-4069-a79d-d486108e46a5"
// const extrusionVideo1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2Fextrusion_video1_trim.mp4?alt=media&token=7510f73f-3daf-4400-a2a9-7aa5e6f30f1d"
// const extrusionVideo2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2Fextrusion_video2_trim.mp4?alt=media&token=8fe56e89-6676-44c6-84e4-d74a3b13214e"

// const leatherPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FDeri%20Desen.webp?alt=media&token=e1305404-3d8a-4483-bc76-2e4cc8439578"
// const finePattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2F%C4%B0nce%20Desen.webp?alt=media&token=69587d4e-2108-4305-b47d-4c4a0f6dfeeb"
// const linenPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FKeten%20Desen.webp?alt=media&token=587c6585-f4e9-403f-b72d-eb9ffab318f1"
// const sandPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FKum%20Desen.webp?alt=media&token=55660829-104f-4b6e-b2b2-6480c6a33423"
// const mercedesPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FMercedes%20Desen.webp?alt=media&token=54fb69f4-a12d-4dc5-801a-1e617969d613"
// const meteorPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FMeteor%20Desen.webp?alt=media&token=e4e8993b-2b29-4201-bd27-2ddbd43794bb"
// const pointPattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FNokta%20Desen.webp?alt=media&token=f99ba8b3-783b-4de7-bad7-06d8ad13508f"
// const r8Pattern = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FsheetExtrusionImg%2FR8%20Desen.webp?alt=media&token=3c9bce8c-a688-42a4-944b-16055db2d2bb"

const extrusionVideo1 = "VOpDMypdXss";
const extrusionVideo2 = "AWA35pXGWxI";

export const sheetExtrusionImages = {
  extrusion1,
};

export const sheetExtrusionVideos = {
  extrusionVideo1,
  extrusionVideo2,
};

export const patterns = {
  finePattern,
  leatherPattern,
  linenPattern,
  mercedesPattern,
  meteorPattern,
  pointPattern,
  r8Pattern,
  sandPattern,
};

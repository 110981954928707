import "./contact.css";
import React, { useState } from "react";

import { contactImages } from "../../assets/images/contactImg";

import TitleBox from "../../components/TitleBox/TitleBox";
import FooterContact from "../../components/FooterContact/FooterContact";
import ContactForm from "../../components/ContactForm/ContactForm";
import InfoModal from "../../components/Modals/InfoModal/InfoModal";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();

  const [infoText, setInfoText] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const handleOpenInfoModal = () => setOpenInfoModal(true);
  const handleCloseInfoModal = () => setOpenInfoModal(false);

  return (
    <div className={`contact-container `}>
      <div className="contact-header">
        <div className="header-back-img">
          <img src={contactImages.contact_header_back} alt="header-img" />
        </div>

        <div className="front-container">
          <div className="header-title">
            <TitleBox title={t("contact")} />
          </div>

          <div className="contact-content">
            <div className="map-container">
              <iframe
                className="map-google"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.1746837581827!2d28.819515575731394!3d40.18292577147812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca05d663473bdd%3A0x30e0efc3121695b1!2sPaye%20Plast%20Ekstr%C3%BCder!5e0!3m2!1str!2str!4v1686745765865!5m2!1str!2str"
                // allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="bottom-container">
              <div className="footer">
                <FooterContact />
              </div>
              <div className="form">
                <ContactForm
                  handleOpenInfoModal={handleOpenInfoModal}
                  setInfoText={setInfoText}
                />

                {openInfoModal && (
                  <InfoModal
                    info={infoText}
                    handleCloseModal={handleCloseInfoModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import "./patternCard.css";

function PatternCard({ image, text, onClick }) {
  return (
    <div className="patterncard-container" onClick={onClick}>
      <div className="image-box">
        <img src={image} alt="pattern-img" />
      </div>
      <h3 className="patterncard-text">{text}</h3>
    </div>
  );
}

export default PatternCard;

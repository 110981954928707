import "./productCard.css";

function ProductCard({ image, text }) {
  return (
    <div className="productcard-container">
      <div className="image-box">
        <img src={image} alt="product-img" />
      </div>
      <h3 className="text">{text}</h3>
    </div>
  );
}

export default ProductCard;

import { useTranslation } from "react-i18next";

function NullDataComponent() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        fontSize: "24px",
        fontWeight: "bold",
      }}
    >
      <span
        style={{
          background: "rgb(233, 38, 38)",
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          display: "grid",
          placeItems: "center",
          marginRight: ".5rem",
        }}
      >
        !
      </span>{" "}
      {t("null_data_text")}
      
    </div>
  );
}

export default NullDataComponent;

import "./navbar.css";
import { useEffect, useState } from "react";
import { icons } from "../../assets/icons/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ModalContextValue } from "../../context/ModalContext";

function Navbar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { openModal } = ModalContextValue();

  const lng = i18n.language;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  async function handleChangeLang(lang) {
    await i18n.changeLanguage(lang);
  }

  // Dropdown menünün açılmasını ve kapalı hâle getirilmesini sağlayan işlev
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Dropdown menünün dışında bir yere tıklandığında menünün kapatılmasını sağlayan işlev
  const handleClickOutside = (event) => {
    if (event.target.closest(".menu-toggle")) {
      return;
    }
    if (isMenuOpen && event.target.closest(".dropdown-menu") === null) {
      setIsMenuOpen(false);
    }
  };

  // Dropdown menüyü kapatmak için tıklama olayını dinlemek üzere etkinleştirme/etkisizleştirme
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  // Menü öğelerine tıklandığında dropdown menünün kapanmasını sağlayan işlev
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav
      style={{
        zIndex: openModal ? 0 : 1,
      }}
      className={`navbar-container ${scrolled ? "scrolled" : ""}`}
    >
      <div className="company-logo">
        <img
          onClick={() => navigate("/")}
          src={icons.payeLogo}
          alt="logo"
        />
      </div>

      <div className="nav-links">
        <ul>
          <li className={pathname === "/" ? "active-link" : ""}>
            <Link to="/">{t("homepage")}</Link>
          </li>
          <li className={pathname === "/about" ? "active-link" : ""}>
            <Link to="/about">{t("about")}</Link>
          </li>
          <li
            className={pathname === "/sheetextrusion" ? "active-link" : ""}
          >
            <Link to="/sheetextrusion">{t("sheetextrusion")}</Link>
          </li>
          <li className={pathname === "/products" ? "active-link" : ""}>
            <Link to="/products">{t("products")}</Link>
          </li>
          <li className={pathname === "/references" ? "active-link" : ""}>
            <Link to="/references">{t("references")}</Link>
          </li>
          <li className={pathname === "/gallery" ? "active-link" : ""}>
            <Link to="/gallery">{t("gallery")}</Link>
          </li>
          <li className={pathname === "/contact" ? "active-link" : ""}>
            <Link to="/contact">{t("contact")}</Link>
          </li>
        </ul>
      </div>

      <div className="nav-right">
        <div className="lang-box">
          <span
            className={lng === "tr" ? "active-lng" : ""}
            onClick={() => handleChangeLang("tr")}
          >
            TR
          </span>
          <span
            className={lng === "en" ? "active-lng" : ""}
            onClick={() => handleChangeLang("en")}
          >
            EN
          </span>
        </div>

        <div className="menu-toggle" onClick={toggleMenu}>
          <span className="material-symbols-outlined">menu</span>
        </div>
      </div>

      {isMenuOpen && (
        <div className="dropdown-menu">
          <ul>
            <li className={pathname === "/" ? "active-link" : ""}>
              <Link to="/" onClick={handleLinkClick}>
                {t("homepage")}
              </Link>
            </li>
            <li className={pathname === "/about" ? "active-link" : ""}>
              <Link to="/about" onClick={handleLinkClick}>
                {t("about")}
              </Link>
            </li>
            <li
              className={
                pathname === "/sheetextrusion" ? "active-link" : ""
              }
            >
              <Link to="/sheetextrusion" onClick={handleLinkClick}>
                {t("sheetextrusion")}
              </Link>
            </li>
            <li className={pathname === "/products" ? "active-link" : ""}>
              <Link to="/products" onClick={handleLinkClick}>
                {t("products")}
              </Link>
            </li>
            <li
              className={pathname === "/references" ? "active-link" : ""}
            >
              <Link to="/references" onClick={handleLinkClick}>
                {t("references")}
              </Link>
            </li>
            <li className={pathname === "/gallery" ? "active-link" : ""}>
              <Link to="/gallery" onClick={handleLinkClick}>
                {t("gallery")}
              </Link>
            </li>
            <li className={pathname === "/contact" ? "active-link" : ""}>
              <Link to="/contact" onClick={handleLinkClick}>
                {t("contact")}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;

import "./contactForm.css";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

function ContactForm({ handleOpenInfoModal, setInfoText }) {
  const { t } = useTranslation();

  const form = useRef();

  const [submitMessageLoading, setSubmitMessageLoading] = useState(false);

  const [contactFormValues, setContactFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;

    setContactFormValues({
      ...contactFormValues,
      [name]: value,
    });
  }

  async function handleSend(e) {
    e.preventDefault();

    console.log("contactFormValues : ", contactFormValues);
    if (
      !contactFormValues.name &&
      !contactFormValues.email &&
      !contactFormValues.message
    ) {
      alert("Form verilerini doldurun!");
    } else {
      setSubmitMessageLoading(true);

      await emailjs
        .send(
          "service_fzchotf",
          "template_s7cb2ei",
          contactFormValues,
          "U-hdPck-2ANv6bmg1"
        )
        .then(
          (result) => {
            console.log(result.text);
            setInfoText(t("message_info"));
          },
          (error) => {
            console.log(error.text);
            setInfoText(error.text);
          }
        );

      handleOpenInfoModal();
      setSubmitMessageLoading(false);

      setContactFormValues({
        name: "",
        email: "",
        message: "",
      });
    }
  }

  return (
    <form className="contact-form-container" ref={form} onSubmit={handleSend}>
      <div className="inputs">
        <input
          disabled={submitMessageLoading}
          required
          type="text"
          name="name"
          value={contactFormValues.name}
          onChange={handleChange}
          placeholder={t("contact_form_name")}
        />
        <input
          disabled={submitMessageLoading}
          required
          type="email"
          name="email"
          value={contactFormValues.email}
          onChange={handleChange}
          placeholder={t("contact_form_email")}
        />
        <textarea
          disabled={submitMessageLoading}
          required
          name="message"
          id="message"
          rows="3"
          value={contactFormValues.message}
          onChange={handleChange}
          placeholder={t("contact_form_message")}
        ></textarea>
      </div>

      <button
        disabled={submitMessageLoading}
        className="send-btn"
        type="submit"
      >
        {submitMessageLoading ? (
          t("btn_send_loading")
        ) : (
          <>
            <span className="material-symbols-outlined">send</span>
            <span>{t("btn_send")}</span>
          </>
        )}
      </button>
    </form>
  );
}

export default ContactForm;

import "./modalCarousel.css";
import React, { useState } from "react";
import YouTube from "react-youtube";
import YoutubeVideo from "../../YoutubeVideo/YoutubeVideo";

function ModalCarousel({
  src,
  selectDataIndex,
  data,
  openModalCarousel,
  handleCloseModalCarousel,
}) {
  const [modalCarouselImageIndex, setModalCarouselImageIndex] =
    useState(selectDataIndex);

  const handleNext = () => {
    if (modalCarouselImageIndex < data.length - 1) {
      setModalCarouselImageIndex(modalCarouselImageIndex + 1);
    } else {
      setModalCarouselImageIndex(0);
    }
  };

  const handlePrev = () => {
    if (modalCarouselImageIndex > 0) {
      setModalCarouselImageIndex(modalCarouselImageIndex - 1);
    } else {
      setModalCarouselImageIndex(data.length - 1);
    }
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: "100%",
    width: "100%",
  };

  console.log(data[modalCarouselImageIndex]);

  return (
    <div className={`modal-carousel-container`}>
      <div
        className={`images-detail-main  ${
          openModalCarousel ? "open-modal" : "out-modal"
        }`}
      >
        <div className="images-detail-close" onClick={handleCloseModalCarousel}>
          X
        </div>

        <div className="modal-image-box">
          {src ? (
            <>
              {" "}
              <img
                src={data[modalCarouselImageIndex]}
                alt="img"
                className="modal-img"
              />
            </>
          ) : (
            <>
              {data && data[modalCarouselImageIndex].type === "video" ? (
                <>
                  {data[modalCarouselImageIndex].duration ? (
                    <div style={{ height: "90%" }}>
                      <YoutubeVideo
                        videoId={data[modalCarouselImageIndex].src}
                        autoplay={true}
                        mute={false}
                      />
                    </div>
                  ) : (
                    <video
                      src={data[modalCarouselImageIndex].src}
                      alt="img"
                      autoPlay
                      controls
                    />
                  )}
                </>
              ) : (
                <img
                  src={data[modalCarouselImageIndex].src}
                  alt="img"
                  className="modal-img"
                />
              )}
            </>
          )}
        </div>

        <button className="images-detail-button-prev" onClick={handlePrev}>
          <span className="material-symbols-outlined">arrow_back_ios_new</span>
        </button>
        <button className="images-detail-button-next" onClick={handleNext}>
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </div>
  );
}

export default ModalCarousel;
// if (data && data[modalCarouselImageIndex].type === "video") {
//   return (
//     <div className={`modal-carousel-container`}>
//       <div
//         className={`images-detail-main  ${
//           openModalCarousel ? "open-modal" : "out-modal"
//         }`}
//       >
//         <div
//           className="images-detail-close"
//           onClick={handleCloseModalCarousel}
//         >
//           X
//         </div>
//         {/* <video
//               src={data[modalCarouselImageIndex].src}
//               alt="img"
//               autoPlay
//               controls
//             /> */}
//         <div className="modal-image-box">
//           {data && data[modalCarouselImageIndex].duration ? (
//             <video
//               src={data[modalCarouselImageIndex].src}
//               alt="img"
//               autoPlay
//               controls
//             />
//           ) : (
//             <img
//               src={data[modalCarouselImageIndex].src}
//               alt="img"
//               className="modal-img"
//             />
//           )}
//         </div>

//         <button className="images-detail-button-prev" onClick={handlePrev}>
//           <span className="material-symbols-outlined">
//             arrow_back_ios_new
//           </span>
//         </button>
//         <button className="images-detail-button-next" onClick={handleNext}>
//           <span className="material-symbols-outlined">arrow_forward_ios</span>
//         </button>
//       </div>
//     </div>
//   );
// } else {
//   return (
//     <div className={`modal-carousel-container`}>
//       <div
//         className={`images-detail-main  ${
//           openModalCarousel ? "open-modal" : "out-modal"
//         }`}
//       >
//         <div
//           className="images-detail-close"
//           onClick={handleCloseModalCarousel}
//         >
//           X
//         </div>

//         <div className="modal-image-box">
//           <img
//             src={data[modalCarouselImageIndex]}
//             alt="img"
//             className="modal-img"
//           />
//         </div>

//         <button className="images-detail-button-prev" onClick={handlePrev}>
//           <span className="material-symbols-outlined">
//             arrow_back_ios_new
//           </span>
//         </button>
//         <button className="images-detail-button-next" onClick={handleNext}>
//           <span className="material-symbols-outlined">arrow_forward_ios</span>
//         </button>
//       </div>
//     </div>
//   );
// }

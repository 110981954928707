import { useNavigate } from "react-router-dom";
import "./titleBox.css";
import { useTranslation } from "react-i18next";

function TitleBox({ button, title }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <nav className="titlebox-container">
      {button && (
        <button className="back-button" onClick={() => navigate(-1)}>
          {t("button_back")}
        </button>
      )}
      <h3 className="title">{title}</h3>
    </nav>
  );
}

export default TitleBox;

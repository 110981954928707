import cookiePolicy from "./GİZLİLİK POLİTİKASI VE ÇEREZLER.pdf";
import kvkPolicy from "./KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ HAKKINDA AYDINLATMA METNİ WEB SİTESİ.pdf";
import termsPolicy from "./KULLANIM KOŞULLARI (1).pdf";
import qualityPolicy from "./PAYE PLAST Kalite Politikası-01[502].pdf";

export const contractsPdf = {
  cookiePolicy,
  kvkPolicy,
  termsPolicy,
};


export const qualityPdf = {
  qualityPolicy
};
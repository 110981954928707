import "./products.css";
import React from "react";

import { useTranslation } from "react-i18next";
import TitleBox from "../../../components/TitleBox/TitleBox";
import ProductCard from "../../../components/ProductCard/ProductCard";
import { productsImage } from "../../../assets/images/productsImg";
import { Link } from "react-router-dom";

function Products() {
  const { t } = useTranslation();

  return (
    <div className="front-container-products">
      <div className="header-title">
        <TitleBox title={t("products")} />
      </div>
      <div className="images-container">
        <Link to={false}>
          <ProductCard image={productsImage.bus} text={t("products_bus")} />
        </Link>
        <Link to={`product-detail/${2}`}>
          <ProductCard
            image={productsImage.minibus}
            text={t("products_minibus")}
          />
        </Link>

        <Link to={false}>
          <ProductCard
            image={productsImage.kamyon}
            text={t("products_truck")}
          />
        </Link>
        {/* <Link to={false}>
          <ProductCard
            image={productsImage.havacilik}
            text={t("products_aviation")}
          />
        </Link> */}

        <Link to={false}>
          <ProductCard
            image={productsImage.traktor}
            text={t("products_tractor")}
          />
        </Link>

        <Link to={false}>
          <ProductCard
            image={productsImage.elekrikli_arac}
            text={t("products_electric_vehicle")}
          />
        </Link>

        {/* <Link to={false}>
          <ProductCard
            image={productsImage.savunma_sanayi}
            text={t("products_defense_industry_tools")}
          />
        </Link> */}

        <Link to={false}>
          <ProductCard
            image={productsImage.otomobil}
            text={t("products_car")}
          />
        </Link>
      </div>
    </div>
  );
}

export default Products;

import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./pages/HomePage/HomePage";
import About from "./pages/About/About";
import Products from "./pages/ProductsLayout/Products/Products";
import Referances from "./pages/Referances/Referances";
import Galery from "./pages/Galery/Galery";
import Contact from "./pages/Contact/Contact";
import { useLayoutEffect } from "react";
import SheetExtrusion from "./pages/SheetExtrusion/SheetExtrusion";
import ProductsLayout from "./pages/ProductsLayout/ProductsLayout";
import ProductDetail from "./pages/ProductsLayout/ProductDetail/ProductDetail";
import { ModalContextValue } from "./context/ModalContext";

function App() {
  const location = useLocation();
  const { openModal } = ModalContextValue();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      style={{
        overflow: openModal ? "hidden" : "auto",
      }}
      className="route-container"
    >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="sheetextrusion" element={<SheetExtrusion />} />

          <Route path="products" element={<ProductsLayout />}>
            <Route index element={<Products />} />
            <Route
              path="product-detail/:productId"
              element={<ProductDetail />}
            />
          </Route>
          <Route path="references" element={<Referances />} />
          <Route path="gallery" element={<Galery />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import headerPng from "./headerPng.webp";
import whoAreWe1Png from "./whoAreWePng1.webp";
import whoAreWe2Png from "./whoAreWePng2.webp";
import whatdowePng1 from "../galeryImg/galeryimg10.webp";
import whatdowePng2 from "./whatdowePng2.webp";
import whatdowePng3 from "./whatdowePng3.webp";
import whatdowePng4 from "./whatdowePng4.webp";
import whatdowePng5 from "./whatdowePng5.webp";
import whatdowePng6 from "./whatdowePng6.webp";

// const headerPng = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FheaderPng.png?alt=media&token=dbdd589e-5442-4610-a7e1-daf9799c831e"
// const whoAreWe1Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhoAreWePng1.png?alt=media&token=c57fff20-4f9c-420a-b499-265d4d7bbf50"
// const whoAreWe2Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhoAreWePng2.png?alt=media&token=50a949d7-d957-4d43-b313-76743e9cdf7d"
// const whatdowePng1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng1.png?alt=media&token=f377a183-0327-4458-8399-f1c20ab5ec7f"
// const whatdowePng2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng2.png?alt=media&token=45c194f0-6e0c-49f4-a495-54798fdfe4d3"
// const whatdowePng3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng3.png?alt=media&token=2aa5bfea-9c0d-4ec8-9a7c-e247d73b5336"
// const whatdowePng4 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng4.jpg?alt=media&token=a67a0971-b6df-46df-8a0c-d9769c162a84"
// const whatdowePng5 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng5.png?alt=media&token=64790144-f0e5-4a30-989c-8c6a800ab594"
// const whatdowePng6 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FhomeImg%2FwhatdowePng6.png?alt=media&token=55ceed93-24ab-47a6-b0f6-c9b6236cd6d2"

export const homepageImage = {
  headerPng,
  whoAreWe1Png,
  whoAreWe2Png,
  whatdowePng1,
  whatdowePng2,
  whatdowePng3,
  whatdowePng4,
  whatdowePng5,
  whatdowePng6,
};

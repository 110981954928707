import "./contractModal.css";

function ContractsModal({ contractTitle, contractText, handleCloseModal }) {
  return (
    <div className="contract-modal-container" onClick={handleCloseModal}>
      <div className="contract-modal-box">
        <div className="modal-close" onClick={handleCloseModal}>
          X
        </div>
        <div className="contract-main">
          <div className="title">
            <span className="material-symbols-outlined">contract</span>
            <h4>{contractTitle}</h4>
          </div>
          <div className="contract-text">
            {/* <p>{contractText}</p> */}
            <iframe
              title="PDF Viewer"
              src={contractText}
              width="100%"
              height="500px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractsModal;

import "./homePage.css";
import React, { useState } from "react";
import { icons } from "../../assets/icons";
import { videos } from "../../assets/video";
import { homepageImage } from "../../assets/images/homepageImg";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo";

function HomePage() {
  const { t } = useTranslation();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: "100%",
    width: "100%",
  };

  return (
    <div className="homepage-container">
      <header>
        <div className="image-box">
          {isVideoPlaying ? (
            // <video
            // src={videos.payeplast_tanitim}
            // alt="video"
            // autoPlay
            // onEnded={handleVideoEnd}
            // />
            <YoutubeVideo autoplay={true} videoId={videos.payeplast_tanitim}  mute={false}/>
          ) : (
            <img src={homepageImage.headerPng} alt="header-img" />
          )}
        </div>
        {!isVideoPlaying && (
          <>
            <h1 className="header-text">
              {t("homepage_header_text1")} <br /> {t("homepage_header_text2")}
            </h1>
            <button
              onClick={() => setIsVideoPlaying(true)}
              className="watch-movie-btn"
            >
              <img src={icons.watchIcon} alt="icon" />
              <span>{t("btn_watch_movie")}</span>
            </button>
          </>
        )}

        <div className="social-media-links">
          <a
            href="https://wa.me/+905496165916"
            target="_blank"
            className="link-icon-box"
          >
            <img src={icons.wpGreenIcon} alt="icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/paye-plast/"
            target="_blank"
            className="link-icon-box"
          >
            <img src={icons.linkedinBlueIcon} alt="icon" />
          </a>
        </div>
      </header>

      <div className="who-are-we">
        <div className="text">
          <h3 className="title">{t("homepage_who_are_we")}</h3>
          <p>
            {t("homepage_who_are_we_text1")} <br />
            {t("homepage_who_are_we_text2")}{" "}
          </p>
          <br />
          <p>{t("homepage_who_are_we_text3")}</p>
          <br />
          <p>{t("homepage_who_are_we_text4")}</p>
          <br />
          <p>{t("homepage_who_are_we_text5")}</p>
        </div>

        <div className="images-content">
          <div className="image-box">
            <img src={homepageImage.whoAreWe1Png} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whoAreWe2Png} alt="image" />
          </div>
        </div>
      </div>

      <div className="what-do-we-produce">
        <div className="text">
          <h3 className="title">{t("homepage_what_we_produce")}</h3>
          <p>{t("homepage_what_we_produce_text1")}</p>

          <ul>
            <li>{t("homepage_what_we_produce_text2")}</li>
            <li>{t("homepage_what_we_produce_text3")}</li>
            <li>{t("homepage_what_we_produce_text4")}</li>
            <li>{t("homepage_what_we_produce_text5")}</li>
            <li>{t("homepage_what_we_produce_text6")}</li>
            <li>{t("homepage_what_we_produce_text7")}</li>
          </ul>
          <p>{t("homepage_what_we_produce_text8")}</p>
        </div>

        <div className="images-container">
          <div className="image-box">
            <img src={homepageImage.whatdowePng1} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whatdowePng2} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whatdowePng3} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whatdowePng4} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whatdowePng5} alt="image" />
          </div>
          <div className="image-box">
            <img src={homepageImage.whatdowePng6} alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
/* WHAT ARE WE ESKİ HTML */
{
  /* <div className="text">
  <h3 className="title">{t("homepage_who_are_we")}</h3>
  <p>
    {t("about_text1")}
    <strong> {t("about_text2")}</strong> {t("about_text3")} {t("about_text4")},{" "}
    {t("about_text5")} <strong>{t("about_text6")}</strong> {t("about_text_and")}{" "}
    <strong>{t("about_text8")}</strong> {t("about_text9")}
  </p>
  <br />
  <p>
    {t("about_text10")} {t("about_text11")} <strong>{t("about_text12")}</strong>{" "}
    {t("about_text_and")} <strong>{t("about_text13")}</strong>{" "}
    {t("about_text14")} {t("about_text15")} {t("about_text16")}{" "}
    <strong>{t("about_text17")}</strong> {t("about_text18")}
  </p>
  <br />
  <p>
    <strong>{t("about_text19")}</strong> {t("about_text20")}
  </p>
  <br />
  <p>
    {t("about_text21")} <strong>{t("about_text22")}</strong>
    {t("about_text23")} <strong>{t("about_text24")}</strong> {t("about_text25")}
  </p>
</div>; */
}

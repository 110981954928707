import "./galeryComponent.css";
import React, { useEffect, useState } from "react";
import { icons } from "../../assets/icons";
import YoutubeVideo from "../YoutubeVideo/YoutubeVideo";

const GaleryComponent = ({ data, handleClick }) => {
  // const [videoDurations, setVideoDurations] = useState({});

  // useEffect(() => {
  //   // Videoların sürelerini almak için bir yardımcı işlev
  //   const getVideoDurations = async () => {
  //     const durationMap = {};

  //     // Videoların sürelerini almak için her videoyu yükleyin
  //     for (const item of data) {
  //       if (item.type === "video") {
  //         const video = document.createElement("video");
  //         video.src = item.src;

  //         // Videonun metadata yüklenmesini bekleyin (süre bilgisi almak için gerekli)
  //         await new Promise((resolve) => {
  //           video.onloadedmetadata = () => resolve();
  //         });

  //         // Videonun süresini alın ve durationMap nesnesine ekleyin
  //         durationMap[item.src] = formatDuration(video.duration);

  //         // Video elementini temizleyin
  //         video.remove();
  //       }
  //     }

  //     // Video sürelerini state'e ekleyin
  //     setVideoDurations((prevDurations) => ({
  //       ...prevDurations,
  //       ...durationMap,
  //     }));
  //   };

  //   getVideoDurations();
  // }, [data]);

  // // Videonun süresini "mm:ss" formatına dönüştürmek için bir yardımcı işlev
  // const formatDuration = (duration) => {
  //   const minutes = Math.floor(duration / 60);
  //   const seconds = Math.floor(duration % 60);
  //   return `${minutes.toString().padStart(2, "0")}:${seconds
  //     .toString()
  //     .padStart(2, "0")}`;
  // };

  return (
    <div className="galery">
      {data.map((item, index) => (
        <div
          className="galery-item"
          key={index}
          onClick={() => handleClick(index)}
        >
          {item.type === "image" ? (
            <img
              src={item.src}
              alt={item.alt}
              className="galery-image"
              onClick={() => handleClick(index)}
            />
          ) : (
            <>
              {/* <video
                src={item.src}
                alt={item.alt}
                className="galery-video"
                onClick={() => handleClick(index)}
                onLoadedMetadata={() => {}}
              /> */}
              <div className="galery-video ytp">
                <YoutubeVideo videoId={item.src} autoplay={false} mute={true} />
              </div>

              <span className="play-border" onClick={() => handleClick(index)}>
                <img src={icons.play_icon} alt="play_icon" />
              </span>
              <span className="minutes-box" onClick={() => handleClick(index)}>
                {/* {videoDurations[item.src] || item.duration || "00:00"} */}
                {item.duration || "00:00"}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default GaleryComponent;

import aboutheader from "./aboutheader.webp";
import payeHistory from "./tarihçe.webp";
import headerVideo1 from "./about_video1.webp";
import qualityPolicyBackImg from "./quality_policy_back_img.webp";
import payeLogo from "./paye_logo.png";

export const aboutImages = {
  aboutheader,
  payeHistory,
  qualityPolicyBackImg,
  payeLogo,
};

export const aboutVideos = {
  headerVideo1,
};

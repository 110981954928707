import payeLogo from "./payeLogo.svg";
import mail from "./mail.svg";
import phone from "./phone.svg";
import map from "./map.svg";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.svg";
import wp from "./wp.svg";
import watchIcon from "./watchIcon.svg";
import wpGreenIcon from "./wpGreenIcon.svg";
import linkedinBlueIcon from "./linkedinBlueIcon.svg";
import play_icon from "./play_icon.svg";

export const icons = {
  payeLogo,
  mail,
  phone,
  map,
  linkedin,
  instagram,
  wp,
  watchIcon,
  wpGreenIcon,
  linkedinBlueIcon,
  play_icon,
};

import "./productDetail.css";

import React, { useEffect, useState } from "react";
import TitleBox from "../../../components/TitleBox/TitleBox";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { productsDetail } from "../../../assets/images/productsDetail";
import ModalCarousel from "../../../components/Modals/ModalCarousel/ModalCarousel";
import { ModalContextValue } from "../../../context/ModalContext";
import NullDataComponent from "../../../components/NullDataComponent/NullDataComponent";

function ProductDetail() {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  const { productId } = useParams();
  const { openModal, setOpenModal } = ModalContextValue();

  const [loading, setLoading] = useState(false);

  const [productName, setProductName] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const [selectDataIndex, setSelectDataIndex] = useState(0);

  // const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const product = productsDetail.find(
      (prd) => Number(prd.productId) === Number(productId)
    );
    if (product) {
      setBrands(product.brands);
      setSelectedBrand(product.brands[0]); // Set the first brand as selected initially

      setProductName(
        product.productName === "OTOBÜS"
          ? t("products_bus")
          : product.productName === "MİNİBÜS"
          ? t("products_minibus")
          : product.productName === "KAMYON"
          ? t("products_truck")
          : product.productName === "HAVACILIK"
          ? t("products_aviation")
          : product.productName === "TRAKTÖR"
          ? t("products_tractor")
          : product.productName === "ELEKTRİKLİ ARAÇ"
          ? t("products_electric_vehicle")
          : product.productName === "SAVUNMA SANAYİ ARAÇLARI"
          ? t("products_defense_industry_tools")
          : product.productName === "OTOMOBİL"
          ? t("products_car")
          : ""
      );
    }
  }, [productId, lng]);

  function handleClick(index) {
    setSelectDataIndex(index);
    handleOpenModal();
  }

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [selectedBrand]);

  return (
    <div className="front-container-product-detail">
      <div className="header-title">
        <TitleBox button={true} title={`${t("products")} / ${productName}`} />
      </div>
      {loading ? (
        <div>{t("loading")}</div> // Burada loading durumu true olduğunda yükleme animasyonu gösterilebilir.
      ) : brands && brands.length > 0 ? (
        <div className="detail-container">
          <div className="detail-links">
            {brands.map((brand, key) => (
              <button
                key={brand.key}
                className={`link-button ${
                  selectedBrand && selectedBrand.name === brand.name
                    ? "active"
                    : ""
                }`}
                onClick={() => setSelectedBrand(brand)}
              >
                {brand.name}
              </button>
            ))}
          </div>

          <div className="detail-images">
            {selectedBrand &&
              selectedBrand.src.map((image, index) => (
                <div
                  key={index}
                  className="image-box"
                  onClick={() => handleClick(index)}
                >
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="brand-image"
                  />
                </div>
              ))}
          </div>
          {openModal && (
            <ModalCarousel
              src={true}
              selectDataIndex={selectDataIndex}
              data={selectedBrand.src}
              openModalCarousel={openModal}
              handleCloseModalCarousel={handleCloseModal}
            />
          )}
        </div>
      ) : (
        <NullDataComponent />
      )}
    </div>
  );
}

export default ProductDetail;

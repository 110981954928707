import { createContext, useContext, useState } from "react";

export const ModalContext = createContext();

function ModalContextProvider({ children }) {
  const [openModal, setOpenModal] = useState(false);

  const values = {
    openModal,
    setOpenModal,
  };

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
}

export const ModalContextValue = () => useContext(ModalContext);

export default ModalContextProvider;

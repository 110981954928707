import galeryHeaderBack from "./galery_header_back.webp";

import galeryimg1 from "./galeryimg1.webp";
import galeryimg2 from "./galeryimg2.webp";
import galeryimg3 from "./galeryimg3.webp";
import galeryimg5 from "./galeryimg5.webp";
import galeryimg6 from "./galeryimg6.webp";
import galeryimg7 from "./galeryimg7.webp";
import galeryimg8 from "./galeryimg8.webp";
import galeryimg9 from "./galeryimg9.webp";
import galeryimg10 from "./galeryimg10.webp";
import galeryimg11 from "./galeryimg11.webp";
import galeryimg12 from "./galeryimg12.webp";
import galeryimg13 from "./galeryimg13.webp";

// const galeryHeaderBack = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgalery_header_back.png?alt=media&token=e5a2a82d-f1dc-460b-81e3-9c485f2fdd65";

// const video3Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo3Png.mp4?alt=media&token=6dfe306d-2561-48db-9809-c0b8865eb58c";
// const video4Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo4Png.mp4?alt=media&token=30df1ba7-be72-428b-a195-63f52c4e584f";
// const video5Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo5Png.mp4?alt=media&token=595b5477-8aeb-4768-8715-373eeb97b167";
// const video6Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo6Png.mp4?alt=media&token=003c17d8-67c5-47fb-bc64-b3cb7c73bc38";
// const video7Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo7Png.mp4?alt=media&token=5d40be87-e866-408a-8369-d34e1eaadea7";
// const video8Png = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fvideo8Png.mp4?alt=media&token=6709bc38-5568-40d3-b57b-81f63be02f3d";

// const galeryimg1 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg1.png?alt=media&token=994b39bf-5873-4329-a70c-fdcd7b65d103";
// const galeryimg2 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg2.png?alt=media&token=4a7c03b6-d2f7-4f4a-95e1-8e1b0edfe81f";
// const galeryimg3 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg3.png?alt=media&token=1f49d197-d050-478c-807a-7a448217c048";
// const galeryimg5= "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg5.webp?alt=media&token=a263d8d7-aed2-40d3-bf37-339dcc8b34a6"
// const galeryimg6= "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg6.webp?alt=media&token=16105910-dc2e-4eb0-a72c-e91afe7361f6"
// const galeryimg7 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg7.webp?alt=media&token=e2023c19-61fc-46d3-87d2-d721396e3335";
// const galeryimg8 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg8.webp?alt=media&token=13c6c891-3e09-49f9-bde6-9186102e4b70";
// const galeryimg9 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg9.webp?alt=media&token=efdf7a64-fe75-4533-8df8-9356f9b46e45";
// const galeryimg10 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg10.webp?alt=media&token=291bd755-9478-41fd-b461-c08bb81dc687";
// const galeryimg11 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg11.webp?alt=media&token=b1b60ca3-35f1-4922-8ebc-3b9e3a2cb476";
// const galeryimg12 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg12.webp?alt=media&token=4baaee5c-5966-42d6-8669-cf0ef4c2b97d";
// const galeryimg13 = "https://firebasestorage.googleapis.com/v0/b/payeplast-web.appspot.com/o/images%2FgaleryImg%2Fgaleryimg13.webp?alt=media&token=e1845cd1-81a8-4c0c-ba35-36b57a4f84c4";

export const galeryImages = {
  galeryimg1,
  galeryimg2,
  galeryimg3,
  galeryimg5,
  galeryimg6,
  galeryimg7,
  galeryimg8,
  galeryimg9,
  galeryimg10,
  galeryimg11,
  galeryimg12,
  galeryimg13,
};

export const galeryHeaderImg = {
  galeryHeaderBack,
};

import "./sheetExtrusion.css";
import React, { useRef, useEffect, useState } from "react";
import TitleBox from "../../components/TitleBox/TitleBox";
import ProductCard from "../../components/ProductCard/ProductCard";
import {
  patterns,
  sheetExtrusionImages,
  sheetExtrusionVideos,
} from "../../assets/images/sheetExtrusionImg";
import { useTranslation } from "react-i18next";
import PatternCard from "../../components/PatternCard/PatternCard";
import ModalCarousel from "../../components/Modals/ModalCarousel/ModalCarousel";
import { ModalContextValue } from "../../context/ModalContext";
import YoutubeVideo from "../../components/YoutubeVideo/YoutubeVideo";

function SheetExtrusion() {
  const { t } = useTranslation();

  const { openModal, setOpenModal } = ModalContextValue();

  const [galeryData, setGaleryData] = useState([]);
  const [selectDataIndex, setSelectDataIndex] = useState(0);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  function handleClick(index) {
    setSelectDataIndex(index);
    handleOpenModal();
  }

  useEffect(() => {
    if (patterns) {
      // Convert the patterns object into an array of its values
      const patternsArray = Object.values(patterns);

      // Set the galleryData state with the patterns array
      setGaleryData(patternsArray);
    }
  }, [patterns]);

  return (
    <div className="sheetextrusion-container">
      <div className="sheetextrusion-header">
        <div className="header-back-img">
          <img src={sheetExtrusionImages.extrusion1} alt="header-img" />
        </div>

        <div className="header-front-container">
          <div className="header-title">
            <TitleBox title={t("sheetextrusion")} />
          </div>

          <div className="header-front-img">
            <div className="video-box">
              {/* <video
                src={sheetExtrusionVideos.extrusionVideo1}
                autoPlay
                controls
                muted
                loop
              ></video> */}
              <YoutubeVideo
                videoId={sheetExtrusionVideos.extrusionVideo1}
                autoplay={true}
                mute={true}
              />
            </div>
            <div className="video-box">
              {/* <video
                src={sheetExtrusionVideos.extrusionVideo1}
                autoPlay
                controls
                muted
                loop
              ></video> */}
              <YoutubeVideo
                videoId={sheetExtrusionVideos.extrusionVideo2}
                autoplay={true}
                mute={true}
              />
            </div>
          </div>

          <section className="sheetextrusion-section">
            <div className="text">
              <p>{t("extrusionpage_text1")}</p>

              <div className="big-image">
                <img src={sheetExtrusionImages.extrusion1} alt="extrusion1" />
              </div>

              <p>{t("extrusionpage_text2")}</p>
            </div>
            <div className="images">
              <PatternCard
                onClick={() => handleClick(0)}
                image={patterns.finePattern}
                text={t("extrusionpage_pattern2")}
              />
              <PatternCard
                onClick={() => handleClick(1)}
                image={patterns.leatherPattern}
                text={t("extrusionpage_pattern1")}
              />
              <PatternCard
                onClick={() => handleClick(2)}
                image={patterns.linenPattern}
                text={t("extrusionpage_pattern3")}
              />
              <PatternCard
                onClick={() => handleClick(3)}
                image={patterns.mercedesPattern}
                text={t("extrusionpage_pattern5")}
              />
              <PatternCard
                onClick={() => handleClick(4)}
                image={patterns.meteorPattern}
                text={t("extrusionpage_pattern6")}
              />
              <PatternCard
                onClick={() => handleClick(5)}
                image={patterns.pointPattern}
                text={t("extrusionpage_pattern7")}
              />
              <PatternCard
                onClick={() => handleClick(6)}
                image={patterns.r8Pattern}
                text={t("extrusionpage_pattern8")}
              />
              <PatternCard
                onClick={() => handleClick(7)}
                image={patterns.sandPattern}
                text={t("extrusionpage_pattern4")}
              />
            </div>
            {openModal && (
              <ModalCarousel
                src={true}
                selectDataIndex={selectDataIndex}
                data={galeryData}
                openModalCarousel={openModal}
                handleCloseModalCarousel={handleCloseModal}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default SheetExtrusion;
